import moment from 'moment';
import { Duration } from './chronos.type';

/**
 * Add function for dates
 *
 * can be used to add any duration to the given date
 * @see {@link Duration}
 */
export function add(date: moment.MomentInput, duration: Duration): Date {
  return moment(date).add(duration).toDate();
}

/**
 * Subtract function for dates
 *
 * can be used to subtract any duration from the given date
 * @see {@link Duration}
 */
export function subtract(date: moment.MomentInput, duration: Duration): Date {
  return moment(date).subtract(duration).toDate();
}
