import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Bundle, BundleInfo } from './bundle.model';

export function provideBundleDataAccess(
  opts: BundleInfo,
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: Bundle,
      useFactory: () => new Bundle(opts),
    },
  ]);
}
