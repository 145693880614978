import { inject, makeEnvironmentProviders } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { logger } from '@fmnts/common/log';
import {
  GeneralHttpError,
  HttpClientOrNetworkError,
} from '@fmnts/shared/errors/data-access';
import {
  logMessageFromSentryBreadcrumb,
  provideSentryDataAccess,
  withErrorHandler,
  withTracing,
} from '@formunauts/shared/vendors/sentry/data-access';
import * as Sentry from '@sentry/angular-ivy';
import { TransactionContext } from '@sentry/types';
import * as O from 'effect/Option';
import * as String from 'effect/String';
import { first, map } from 'rxjs';
import { Settings } from '../config/settings';

export function provideAppSentry() {
  /**
   * Environments in which the user feedback form should
   * be shown.
   */
  const envWithUserFeedback = ['staging'];

  return makeEnvironmentProviders([
    provideSentryDataAccess(
      {
        resolveOptions: (
          cfg = inject(AppConfig),
          log = logger({ name: 'Sentry' }),
        ) => {
          const env = cfg.env;

          return cfg.settings$.pipe(
            first(),
            map((settings) => {
              const opts = _getFromSettings(env.production, settings);
              const shouldShowUserFeedbackForm = envWithUserFeedback.includes(
                opts.environment ?? '',
              );

              return {
                ...opts,
                // integrations
                integrations: [
                  Sentry.browserTracingIntegration({
                    enableInp: true,
                    beforeStartSpan: (context) =>
                      _transformTransactionPaths(context),
                  }),
                  Sentry.breadcrumbsIntegration({
                    dom: {
                      serializeAttribute: ['data-testid', 'id', 'class'],
                    },
                  }),
                  Sentry.replayIntegration(),
                ],
                // functions
                beforeSend: (ev) => {
                  if (shouldShowUserFeedbackForm) {
                    // Check if it is an exception, and if so, show the report dialog
                    if (ev.exception && ev.event_id) {
                      Sentry.showReportDialog({ eventId: ev.event_id });
                    }
                  }

                  return ev;
                },
                beforeBreadcrumb: (breadcrumb, hint) => {
                  if (_shouldLogBreadcrumb(breadcrumb)) {
                    log.log(logMessageFromSentryBreadcrumb(breadcrumb));
                  }
                  return breadcrumb;
                },
              };
            }),
          );
        },
      },
      withErrorHandler({
        extractor: () => _extractError,
        config: (cfg = inject(AppConfig)) => ({
          logErrors: !cfg.env.production,
        }),
      }),
      withTracing(),
    ),
  ]);
}

function _getFromSettings(
  enabled: boolean,
  settings: Settings,
): Sentry.BrowserOptions {
  return {
    // general
    enabled,
    dsn: settings.sentryDsn,
    environment: settings.sentryEnvironment,
    release: settings.sentryRelease,
    transportOptions: {
      headers: {
        'ngsw-bypass': 'true',
      },
    },
    tunnel: O.getOrUndefined(_getSentryTunnel(settings)),
    // errors
    ignoreErrors: [
      // Errors caused by `WKWebView`
      // see https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
      `document.getElementsByTagName('video')[0].webkitExitFullScreen`,
      `evaluating 'window.webkit.messageHandlers`,
    ],
    // tracing
    tracesSampleRate: _getTraceSampleRate(settings),
    // replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: _getReplayOnErrorSampleRate(settings),
  };
}

/**
 * @param settings Settings on which the sample rate is based.
 * @returns
 * A percent value of how much transactions should be sampled.
 * The value will depend on the environment.
 */
function _getTraceSampleRate(settings: Settings): number {
  switch (settings.sentryEnvironment) {
    case 'staging':
      // Capture 100% of transactions on staging for
      // performance monitoring
      return 1.0;
    case 'production':
      // Only trace 10% on production as there are a lot more clients
      return 0.1;
    default:
      // All other environments shouldn't trace transactions
      return 0;
  }
}

/**
 * @param settings Settings on which the session replay rate is based.
 * @returns
 * A percent value of how many Session Replays should be captured for Errors.
 * The value will depend on the environment.
 */
function _getReplayOnErrorSampleRate(settings: Settings): number {
  switch (settings.sentryEnvironment) {
    case 'staging':
      // Capture 100% of replays on staging for errors
      return 1.0;
    default:
      // All other environments shouldn't capture replays
      return 0;
  }
}

/**
 * Renames the transaction paths, in order to keep them grouped
 * properly on Sentry.
 *
 * @param context
 *
 * @returns modified context
 */
function _transformTransactionPaths(
  context: TransactionContext,
): TransactionContext {
  // Change all digit/chars of lenght 10 to `:hash` (used for verify-urls)
  // Change all digits to `:id`
  let name = location.pathname
    .replace(/\/[a-f0-9]{10}/g, '/:hash')
    .replace(/\/\d+/g, '/:id');

  // Transaction paths can differ by an additional '/' character at the end
  // This will append the '/' when it's not present
  name += name.endsWith('/') ? '' : '/';

  return {
    ...context,
    name,
  };
}

function _getSentryTunnel(settings: Settings): O.Option<string> {
  return O.flatMap(
    O.fromNullable(settings.sentryTunnel),
    O.liftPredicate(String.isNonEmpty),
  );
}

const SKIP_LOG_BY_TYPE_REGEX = /^(default|debug)/;
const SKIP_LOG_BY_CATEGORY_REGEX = /^(console|navigation|ui|sentry|ngrx)/;
function _shouldLogBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  // See https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  const { category, type } = breadcrumb;

  if (
    (type && SKIP_LOG_BY_TYPE_REGEX.test(type)) ||
    (category && SKIP_LOG_BY_CATEGORY_REGEX.test(category))
  ) {
    return false;
  } else if (type === 'http' || category === 'xhr') {
    // Not logging http requests for now
    return false;
  }

  return true;
}

/**
 * Extracts the relevant information from known errors.
 *
 * @param error Some error that was thrown
 * @returns
 */
function _extractError(error: unknown): unknown {
  if (error instanceof GeneralHttpError) {
    return _extractFromGeneralHttpError(error);
  }

  return error;
}

/**
 * Extracts inner error from `GeneralHttpError`.
 *
 * @returns
 * Inner error
 */
function _extractFromGeneralHttpError({
  error,
  httpError,
}: GeneralHttpError): Error {
  // Special handling in case that we're dealing with an error from the
  // network layer and not directly from the API.
  if (error instanceof HttpClientOrNetworkError) {
    return httpError;
  }
  return error;
}
